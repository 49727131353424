<template>
  <div>
    <vs-card>
      <!-- <div slot="header">
        <feather-icon
          icon="ChevronLeftIcon"
          svgClasses="h-4 w-4"
          class="mr-2 cursor-pointer"
          @click="$router.push({name: 'franchise-management'})"
        ></feather-icon>
        <vs-label style="font-weight: bold">Franchise List</vs-label>
      </div>-->
      <h4 class="mb-2 ml-5">Franchise Details</h4>
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div class="w-full" align="right">
            <vs-button size="normal" @click="saveData" class="m-2"
              >Save Details</vs-button
            >
            <!-- <vs-button color="danger" @click="popUpDelete=true">Delete</vs-button> -->
            <vs-popup :active.sync="popUpDelete" title="Delete Confirmation">
              <p align="center">Are you sure you want to delete this user?</p>
              <br />
              <vs-row
                vs-align="flex-start"
                vs-type="flex"
                vs-justify="left"
                vs-w="12"
                vs-mt="6"
              >
                <vs-col vs-type="flex" vs-align="left" vs-w="6">
                  <div class="w-full m-5" align="right">
                    <vs-button
                      size="lg"
                      color="primary"
                      @click="deleteFranchiseHandler"
                      >Yes, delete</vs-button
                    >
                  </div>
                </vs-col>
                <vs-col vs-type="flex" vs-align="left" vs-w="6">
                  <div class="w-full m-5">
                    <vs-button
                      size="lg"
                      color="danger"
                      @click="popUpDelete = false"
                      >Cancel</vs-button
                    >
                  </div>
                </vs-col>
              </vs-row>
            </vs-popup>
            <vs-button @click="resetPasswordHandler"  v-if="check_has_permission('changeFranchisePassword')" class="m-2"
              >Change Password</vs-button
            >
          </div>
        </vs-col>
      </vs-row>
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-align="left" vs-w="3">
          <div class="w-full m-5">
            <label for>Disable User</label>
            <vs-switch
              color="danger"
              @click="changeStatus(userStatus)"
              class="m-2"
              v-model="userStatus"
            />
            <div
              v-if="statusChangedClick"
              style="width: 20px; height: auto; display: flex"
            >
              <span style="margin-right: 5px">Processing</span>

              <img
                src="../../../../public/loader.gif"
                style="width: 100%; height: 100%"
              />
            </div>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="3">
          <div class="w-full m-5">
            <label for>Admin Approval</label>
            <vs-switch
              @click="changeAdminApproval(franchiseData.hasApprovedByAdmin)"
              class="m-2"
              v-model="franchiseData.hasApprovedByAdmin"
            />
            <div
              v-if="adminApprovalChangeClick"
              style="width: 20px; height: auto; display: flex"
            >
              <span style="margin-right: 5px">Processing</span>

              <img
                src="../../../../public/loader.gif"
                style="width: 100%; height: 100%"
              />
            </div>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <!-- <label for>Email</label>
             <vs-col vs-type="flex" vs-align="left" vs-w="12">
               <p style="margin-right: 5px; font-size: 1rem">
                 {{ franchiseData.email }}
               </p>-->
            <vs-row vs-type="flex" vs-align="left" class="w-full">
              <vs-input
                :success="
                !errors.first('basic.email') && franchiseData.email != ''
              "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.email') ? true : false"
                v-validate="'required|email'"
                name="email"
                data-vv-as="email"
                label="Email"
                class="w-full"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.email')"
                v-model="franchiseData.email"
              />
            </vs-row>
            <vs-row vs-type="flex" vs-align="left" class="w-full">
              <vx-tooltip
                v-if="franchiseData.emailVerifiedAt"
                text="Verified"
                style="display: flex"
              >
                <feather-icon
                  style="color: rgba(var(--vs-primary), 1) !important"
                  icon="CheckCircleIcon"
                  svgClasses="h-5 w-5"
                  class="mr-2 cursor-pointer"
                ></feather-icon>
              </vx-tooltip>
              <vx-tooltip v-else text="Unverified" style="display: flex">
                <feather-icon
                  style="color: rgba(var(--vs-danger), 1) !important"
                  icon="AlertCircleIcon"
                  svgClasses="h-5 w-5"
                  class="mr-2 cursor-pointer"
                ></feather-icon>
              </vx-tooltip>
              <VerifyEmail
                @onSuccessfullyVerified="onSuccessfullyVerified"
                v-if="!franchiseData.emailVerifiedAt"
                :userId="franchiseId"
              />
              <!--            </vs-col>-->
            </vs-row>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :success="
                !errors.first('basic.firstName') &&
                franchiseData.firstName != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('basic.firstName') ? true : false"
              v-validate="'required|max:150'"
              name="firstName"
              data-vv-as="first name"
              label="First Name"
              class="w-full"
              data-vv-scope="basic"
              :danger-text="errors.first('basic.firstName')"
              v-model="franchiseData.firstName"
            />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :success="
                !errors.first('basic.lastName') && franchiseData.lastName != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('basic.lastName') ? true : false"
              v-validate="'required|max:150'"
              name="lastName"
              data-vv-as="last name"
              label="Last Name"
              class="w-full"
              data-vv-scope="basic"
              :danger-text="errors.first('basic.lastName')"
              v-model="franchiseData.lastName"
            />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :success="
                !errors.first('basic.companyName') &&
                franchiseData.companyName != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('basic.companyName') ? true : false"
              v-validate="'required'"
              name="companyName"
              data-vv-as="company name"
              label="Company Name"
              class="w-full"
              data-vv-scope="basic"
              :danger-text="errors.first('basic.companyName')"
              v-model="franchiseData.companyName"
            />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :success="
                !errors.first('basic.franchiseBusinessName') &&
                franchiseData.franchiseBusinessName != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="
                errors.first('basic.franchiseBusinessName') ? true : false
              "
              name="franchiseBusinessName"
              :danger-text="errors.first('basic.franchiseBusinessName')"
              data-vv-as="franchise name"
              label="Franchise Business Name (optional)"
              v-validate="'max:150'"
              class="w-full"
              data-vv-scope="basic"
              v-model="franchiseData.franchiseBusinessName"
            />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :success="!errors.first('basic.abn') && franchiseData.abn != ''"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('basic.abn') ? true : false"
              v-validate="'required'"
              name="abn"
              :danger-text="errors.first('basic.abn')"
              data-vv-as="ABN"
              label="ABN"
              class="w-full"
              data-vv-scope="basic"
              v-model="franchiseData.abn"
            />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <label class="vs-input--label">Franchise Street Address</label>
            <div
              class="vs-component vs-con-input-label vs-input w-full vs-input-primary is-label-placeholder"
              style="margin-top: -1px"
              :class="{
                'input-icon-validate-danger':
                  errors.first('displayAddress') ||
                  franchiseData.address.displayAddress ||
                  checkDisplayAddress != franchiseData.address.displayAddress,
                'input-icon-validate-success':
                  checkDisplayAddress == franchiseData.address.displayAddress,
              }"
            >
              <div class="vs-con-input">
                <input
                  type="text"
                  placeholder
                  ref="autocomplete"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  data-vv-as="Address"
                  name="displayAddress"
                  v-model="franchiseData.address.displayAddress"
                  class="vs-inputx vs-input--input normal"
                  :class="{ hasValue: franchiseData.address.displayAddress }"
                  style="border: 1px solid rgba(0, 0, 0, 0.2)"
                />
                <!--                <span class="input-span-placeholder vs-input--placeholder normal normal vs-placeholder-label">-->
                <!--                            Select the address</span>-->
                <span class="input-icon-validate vs-input--icon-validate">
                  <i
                    v-if="
                      checkDisplayAddress ==
                      franchiseData.address.displayAddress
                    "
                    valiconpack="material-icons"
                    class="vs-icon notranslate icon-scale material-icons null"
                    >done</i
                  >
                  <i
                    v-else-if="
                      errors.first('displayAddress') ||
                      franchiseData.address.displayAddress ||
                      checkDisplayAddress !=
                        franchiseData.address.displayAddress
                    "
                    valiconpack="material-icons"
                    class="vs-icon notranslate icon-scale material-icons null"
                    >error</i
                  >
                </span>
              </div>
              <span>
                <div class="con-text-validation vs-input--text-validation">
                  <span
                    class="span-text-validation span-text-validation-success vs-input--text-validation-span"
                  ></span>
                </div>
              </span>
              <span class="text-danger" style="font-size: 0.75em">{{
                errors.first("displayAddress")
              }}</span>
            </div>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :success="
                !errors.first('basic.postalAddress') &&
                franchiseData.postalAddress != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('basic.postalAddress') ? true : false"
              name="postalAddress"
              data-vv-scope="basic"
              :danger-text="errors.first('basic.postalAddress')"
              data-vv-as="postal address"
              label="Head Office Postal Address (optional)"
              class="w-full"
              v-model="franchiseData.postalAddress"
            />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :success="
                !errors.first('basic.bestContactName') &&
                franchiseData.bestContactName != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              data-vv-scope="basic"
              :danger="errors.first('basic.bestContactName') ? true : false"
              v-validate="'required|max:150'"
              :danger-text="errors.first('basic.bestContactName')"
              name="bestContactName"
              data-vv-as="best contact name"
              label="Best Contact Name"
              class="w-full"
              v-model="franchiseData.bestContactName"
            />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :success="
                !errors.first('basic.bestContactEmail') &&
                franchiseData.bestContactEmail != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('basic.bestContactEmail') ? true : false"
              v-validate="'required|email'"
              data-vv-scope="basic"
              name="bestContactEmail"
              :danger-text="errors.first('basic.bestContactEmail')"
              data-vv-as="best contact email"
              label="Best Contact Email"
              class="w-full"
              v-model="franchiseData.bestContactEmail"
            />
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :danger="errors.first('basic.invoiceEmail') ? true : false"
              :success="
                !errors.first('basic.invoiceEmail') &&
                franchiseData.invoiceEmail != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-if="franchiseData.wantCentralisebilling"
              v-validate="'required_if:centralise,true|email'"
              data-vv-validate-on="blur"
              data-vv-as="invoice email address"
              data-vv-scope="basic"
              label="Invoice Email Address"
              name="invoiceEmail"
              placeholder="Invoice Email Address"
              v-model="franchiseData.invoiceEmail"
              class="w-full"
            />
            <span class="text-danger text-sm">{{
              errors.first("basic.invoiceEmail")
            }}</span>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-checkbox
              ref="centralise"
              v-model="franchiseData.wantCentralisebilling"
              class="mt-6"
              >Do you require centralised billing ?</vs-checkbox
            >
          </div>
        </vs-col>
      </vs-row>
    </vs-card>
    <div id="resetPasswordContainer">
      <vs-card v-if="resetPassword == true">
        <h4 class="mb-2 ml-5 mt-2">Reset Password</h4>
        <form autocomplete="off">
          <vs-row
            vs-align="flex-start"
            vs-type="flex"
            vs-justify="left"
            vs-w="12"
          >
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-input
                  type="password"
                  :success="
                    !errors.first('resetPassword.newPassword') && password != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="
                    errors.first('resetPassword.newPassword') ? true : false
                  "
                  name="newPassword"
                  :danger-text="errors.first('resetPassword.newPassword')"
                  label="New Password"
                  class="w-full"
                  v-validate="{
                    required: true,
                    min: 8,
                    regex: /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9_.*\W]+$)/,
                  }"
                  data-vv-as="new password"
                  data-vv-scope="resetPassword"
                  ref="password"
                  v-model="password"
                />
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-input
                  type="password"
                  label="Confirm Password"
                  :success="
                    !errors.first('resetPassword.confirmPassword') &&
                    confirmPassword != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="
                    errors.first('resetPassword.confirmPassword') ? true : false
                  "
                  name="confirmPassword"
                  :danger-text="errors.first('resetPassword.confirmPassword')"
                  v-validate="'required|confirmed:password'"
                  data-vv-as="confirm password"
                  data-vv-scope="resetPassword"
                  class="w-full"
                  v-model="confirmPassword"
                />
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-button
                  size="lg"
                  @click="changePassword"
                  icon-pack="feather"
                  class="mr-2"
                  >Reset Password</vs-button
                >
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6">
              <div class="w-full m-5" align="right">
                <vs-button
                  @click="resetPassword = false"
                  color="danger"
                  icon-pack="feather"
                  class="mr-2"
                  >Cancel</vs-button
                >
              </div>
            </vs-col>
          </vs-row>
        </form>
      </vs-card>
    </div>
    <franchise-associate-doctor
      :canDelete="true"
      viewRoute="AdminDoctorEdit"
      viewDetailRoute="SubAdminDoctorDetail"
      :canCreate="true"
      createRoute="AdminFranchiseManagementDoctorsCreate"
      :franchiseId ="franchiseId"
      v-if="franchiseId"
    />
    <nurse-table
      :canDelete="true"
      viewRoute="AdminNurseDetails"
      :canCreate="true"
      :franchiseId ="franchiseId"
      v-if="franchiseId"
      createRoute="AdminFranchiseNursesCreate"
      viewDetailRoute="SubAdminNurseDetail"
      class="mt-5"
    />
    <clinicList
      :canEdit="true"
      editRoute="AdminClinicEdit"
      :canCreate="true"
      createRoute="AdminFranchiseClinicsCreate"
      :canViewDetail="true"
      viewRoute="AdminClinicDetail"
      class="mt-5"
    />
  </div>

</template>

<script>
import { mapActions } from "vuex";
import VerifyEmail from "@/views/general-component/VerifyEmail";
import franchiseAssociateDoctor from "../../components/doctors/franchiseAssociateDoctor";
import nurseTable from "../../components/nurses/nurseTable.vue";
import clinicList from "../../components/clinics/vendorAssociatedClinicList.vue";


export default {
  components: {
    VerifyEmail,
    franchiseAssociateDoctor,
    nurseTable,
    clinicList

  },
  data: () => ({
    statusChangedClick: false,
    adminApprovalChangeClick: false,
    franchiseId: "",
    resetPassword: false,
    password: "",
    confirmPassword: "",
    popUpDelete: false,
    franchiseData: {
      firstName: "",
      lastName: "",
      companyName: "",
      franchiseBusinessName: "",
      abn: "",
      address: {
        displayAddress: "",
        state: "",
        suburb: "",
        postCode: "",
        geometry: {
          type: "Point",
          coordinates: [],
        },
      },
      postalAddress: "",
      bestContactName: "",
      bestContactEmail: "",
      wantCentralisebilling: false,
      invoiceEmail: "",
      status: "",
      hasApprovedByAdmin: false,
      emailVerifiedAt: null,
      email: "",
    },
    userStatus: false,
  }),
  methods: {
    ...mapActions("franchise", [
      "fetchFranchiseDetail",
      "updateFranchiseDetail",
      "resetFranchisePassword",
      "deleteFranchise",
    ]),
    async saveData() {
      let self = this;
      if (self.franchiseData.address.displayAddress === "") {
        self.errors.add({
          field: "displayAddress",
          msg: "The franchise street address field is required",
        });
      }
      let isValid = await this.$validator.validateAll("basic");
      if (self.franchiseData.address.displayAddress === "") {
        self.errors.add({
          field: "displayAddress",
          msg: "The franchise street address field is required",
        });
        return false;
      }
      if (isValid) {
        if (self.franchiseData.address.displayAddress === "") {
          self.errors.add({
            field: "displayAddress",
            msg: "The franchise street address field is required",
          });
          return false;
        }

        if (
          self.franchiseData.address.geometry.coordinates.length === 0 ||
          this.checkDisplayAddress !== self.franchiseData.address.displayAddress
        ) {
          self.errors.add({
            field: "displayAddress",
            msg:
              "Please choose address from google address suggestion so that  it will provide us coordinates",
          });
          return false;
        }

        if (this.userStatus == false) this.franchiseData.status = "Active";
        if (this.userStatus == true) this.franchiseData.status = "Disabled";
        await this.updateFranchiseDetail({
          id: this.franchiseId,
          data: this.franchiseData,
        })
          .then((res) => {
            self.$vs.notify({
              title: "Franchise Updated",
              text: "Franchise's details updated Successfully.",
              color: "success",
            });
            self.$router.push("/admin/franchises");
          })
          .catch((err) => {
            self.$vs.notify({
              title: "Failed",
              text: "Failed to update franchise. Try again!",
              color: "danger",
            });
            isValid = false;
            if (err.status === 422) {
              self.mapServerErrors(self, err, "basic");
            }
            if (err.status === 417) {
              if (err.data.title === "Invalid email") {
                self.errors.add({
                  field: "email",
                  msg: err.data.message,
                  scope: "basic",
                });
              } else if (err.data.title === "Invalid ABN") {
                self.errors.add({
                  field: "abn",
                  msg: err.data.message,
                  scope: "basic",
                });
              }
            }
          });
      }
    },
    resetPasswordHandler() {
      this.resetPassword = true;
      document.getElementById("resetPasswordContainer").scrollIntoView();
    },
    onSuccessfullyVerified(verifiedAt) {
      this.franchiseData.emailVerifiedAt = verifiedAt;
    },
    async changePassword() {
      let self = this;
      const isValid = await this.$validator.validateAll("resetPassword");
      if (isValid) {
        await this.resetFranchisePassword({
          id: this.franchiseId,
          data: {
            password: this.password,
            confirmPassword: this.confirmPassword,
          },
        }).then((res) => {
          self.password = "";
          self.confirmPassword = "";
          self.$validator.reset();
          self.$vs.notify({
            title: "Franchise password reset",
            text: "Franchise's password reset Successfully.",
            color: "success",
          });
        });
      }
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function (value, key) {
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, ""),
        });
      });
    },
    async getFranchiseDetail(id) {
      let self = this;
      await this.fetchFranchiseDetail(id).then((res) => {
        const franchise = res.data.data;
        self.franchiseId = franchise._id;
        self.franchiseData.firstName = franchise.firstName;
        self.franchiseData.lastName = franchise.lastName;
        self.franchiseData.companyName = franchise.companyName;
        self.franchiseData.franchiseBusinessName =
          franchise.franchiseBusinessName;
        self.franchiseData.abn = franchise.abn;
        self.franchiseData.postalAddress = franchise.postalAddress;
        self.franchiseData.bestContactName = franchise.bestContactName;
        self.franchiseData.bestContactEmail = franchise.bestContactEmail;
        self.franchiseData.wantCentralisebilling =
          franchise.wantCentralisebilling;
        self.franchiseData.invoiceEmail = franchise.invoiceEmail;
        self.franchiseData.address = franchise.address;
        self.checkDisplayAddress = franchise.address.displayAddress;
        self.franchiseData.status = franchise.status;
        self.franchiseData.hasApprovedByAdmin = franchise.hasApprovedByAdmin;
        self.franchiseData.emailVerifiedAt = franchise.emailVerifiedAt;
        self.franchiseData.email = franchise.email;
      });
      if (self.franchiseData.status == "Active") {
        this.userStatus = false;
      } else {
        this.userStatus = true;
      }
    },
    deleteFranchiseHandler() {
      this.popUpDelete = false;
      let self = this;
      let id = self.franchiseId;
      this.deleteFranchise(id).then((res) => {
        self.$vs.notify({
          title: "Franchise deleted",
          text: "Franchise deleted Successfully.",
          color: "success",
        });
        self.$router.push({ name: "franchise-management" });
      });
    },
    place_changed(a) {},
    async changeStatus(status) {
      let us = "Active";
      if (!status) us = "Disabled";

      let updateData = {
        status: us,
      };

      // if (us === "Active" && !this.franchiseData.emailVerifiedAt) {
      //   updateData.emailVerifiedAt = new Date().toISOString();
      // }

      await this.updateFranchiseDetail({
        id: this.franchiseId,
        data: updateData,
      })
        .then((res) => {
          // this.franchiseData.emailVerifiedAt = res.data.data.emailVerifiedAt;
          this.$vs.notify({
            title: "Success",
            text: "Status updated successfully.",
            color: "success",
          });
          this.statusChangedClick = false;
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Failed",
            text: "Failed to update status. Try again!",
            color: "danger",
          });
          this.statusChangedClick = false;
        });
    },
    async changeAdminApproval(status) {
      this.adminApprovalChangeClick = true;
      await this.updateFranchiseDetail({
        id: this.franchiseId,
        data: { hasApprovedByAdmin: !status },
      })
        .then((res) => {
          this.$vs.notify({
            title: "Success",
            text: "Admin approval updated successfully.",
            color: "success",
          });
          this.adminApprovalChangeClick = false;
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Failed",
            text: "Failed to update admin approval. Try again!",
            color: "danger",
          });
          this.adminApprovalChangeClick = false;
        });
    },
  },
  mounted() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete,
      { types: ["geocode"], componentRestrictions: { country: "au" } }
    );
    this.autocomplete.addListener("place_changed", () => {
      let place = this.autocomplete.getPlace();
      this.errors.remove("displayAddress");
      let ac = place.address_components;
      this.checkDisplayAddress = place.formatted_address;
      let lat = place.geometry.location.lat();
      let lon = place.geometry.location.lng();
      this.franchiseData.address.displayAddress = place.formatted_address;
      this.franchiseData.address.geometry.coordinates[0] = lon;
      this.franchiseData.address.geometry.coordinates[1] = lat;
      this.franchiseData.address.suburb =
        place.address_components[0].short_name;
      this.franchiseData.address.state = place.address_components[1].short_name;
    });
  },
  async created() {
    let franchiseId = this.$route.params.franchiseId;
    await this.getFranchiseDetail(franchiseId);
  },
};
</script>
